import React from 'react'
import Layout from '../components/layout'
import { StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import { ImQuotesLeft, ImQuotesRight } from 'react-icons/im'

const Quote = styled.div`
  font-size: 1.5rem;
  font-weight: 700;
  margin: auto;
  padding: 4rem 1rem;
  text-align: center;
  width: 100%;

  @media only screen and (min-width: 1440px) {
    padding: 4rem 2rem;
    font-size: 1.8rem;
    width: 50%;
  }
`
const Container = styled.div`
  padding-top: 70px;
`
const Row = styled.div`
  display: flex;
  margin: 2rem auto;
  width: 70%;
  flex-direction: column;
  gap: 1rem;

  @media only screen and (min-width: 980px) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`

const TextDiv = styled.div`
  width: 100%;

  h2 {
    font-size: 1rem !important;
    font-weight: 400;
    line-height: 1.15rem;
  }

  @media only screen and (min-width: 1440px) {
    padding: 4rem;
  }
`

const ImageDivRight = styled.div`
  width: 100%;
  transform: rotate(1deg) translateX(2%);
  border: 4px solid white;
  box-shadow: 0 0 1px black;

  @media only screen and (min-width: 980px) {
    transform: rotate(1deg) translateX(0);
  }
`

const ImageDivLeft = styled.div`
  width: 100%;
  transform: rotate(-1deg) translateY(-15%) translateX(-2%);
  border: 4px solid white;
  box-shadow: 0 0 1px black;

  @media only screen and (min-width: 980px) {
    transform: rotate(-1deg) translateY(0) translateX(0);
  }
`

const OmOss = () => (
  <Layout
    seo="Cykelbutik &amp; Cykelverkstad i Åre"
    meta="I vår butik i Åre säljer vi allt från cyklar, komponenter, kläder, skydd och
  tillbehör. Vi har specialiserat oss på dämparservice där vi erbjuder fullständig service av din gaffel
  och dämpare."
  >
    <Container>
      <Row>
        <TextDiv>
          <h1>
            Bike Republic är Åres nya cykelbutik och verkstad, belägen mitt i
            byn
          </h1>
          <h2>
            Vi säljer allt från cyklar, komponenter, kläder, skydd och
            tillbehör. Vi har stort fokus på verkstad och har specialiserat oss
            på dämparservice där vi erbjuder fullständig service av din gaffel
            och dämpare.
          </h2>
          <p>
            Vi har valt att jobba med märken som vi själva använder och kan gå i
            god för. Vi har märken som vi är ensamma med att erbjuda i Sverige.
            Vi säljer cyklar från Cannondale, Rocky Mountain och Transition, så
            oavsett vad du cyklar för disciplin kan vi erbjuda en cykel som
            passar.
          </p>
        </TextDiv>
        <ImageDivRight>
          <StaticImage
            src="../images/page-images/bike-republic-butik-fasad.jpg"
            alt="Om oss - Bike Republic"
          />
        </ImageDivRight>
      </Row>
      <Row>
        <Quote>
          <ImQuotesLeft /> Bike Republic startades eftersom vi kände att det
          behövdes ett komplement till det utbud som fanns i Åre samt att vi
          tyckte att Sveriges främsta cykeldestination behövde en riktig
          cykelverkstad. <ImQuotesRight />
        </Quote>
      </Row>
      <Row>
        <ImageDivLeft>
          <StaticImage
            src="../images/page-images/bike-republic-cykelmeck.jpg"
            alt="Om oss - Bike Republic"
          />
        </ImageDivLeft>
        <TextDiv>
          <p>
            Vår fullutrustade verkstad kan hjälpa dig med alla möjliga problem
            och ser till att din cykel alltid är i toppskick. Vi kan dessutom,
            som enda verkstad i Åre, erbjuda fullständig dämparservice. Vårt mål
            är att Bike Republic skall vara mer än en cykelbutik. Vi vill vara
            den självklara umgängesplatsen för cyklister i Åre.
          </p>
          <p>
            Vi som jobbar här är riktiga cykelnördar och pratar gärna cykel
            dagarna i ända. Kom förbi och prata cykel, dela dina äventyr och
            umgås med andra likasinnade. Cykling är kul och roligast när den får
            delas.
          </p>
        </TextDiv>
      </Row>
    </Container>
  </Layout>
)

export default OmOss
